(function () {
  app.controller('ChecklistCtrl', [
    "$scope", '$timeout', '$sce', 
    function ($scope, $timeout, $sce) {
      $scope.init = function () {};

      $scope.trust = function (html) {
        return $sce.trustAsHtml(html);
      }

      $scope.saveReviewChecklist = function (status) {
        let params = {
          status: status,
          checklist_html: $scope.checklist_html,
          same_box_ids: $('#same_box_ids').val()
        }
        if (status == "all_same_box" && !$('#same_box_ids').val() ) {
          toastr.error("You don't have the same box right now");
          return;
        }
        $.ajax({
          // beforeSend: function(){
          //   $(".loading").hide();
          //   $(".loading").attr('style', 'background: rgba(0, 0, 0, 0)');
          //   $(".loading").attr('style', 'z-index: -1 !important');
          // },
          url: $scope.save_review_checklist_url,
          dataType: "json",
          cache: false,
          type: "POST",
          data: params,
          success: function (rs) {
            $timeout(function () {
              if (rs.succeed) {
                toastr.success(rs.message);
                location.href = $scope.checklist_box_url;
              } else {
                toastr.error(rs.message);
              }
            });
          },
        });
      }

      $scope.getChecklistHTML = function () {
        $.ajax({
          // beforeSend: function(){
          //   $(".loading").hide();
          //   $(".loading").attr('style', 'background: rgba(0, 0, 0, 0)');
          //   $(".loading").attr('style', 'z-index: -1 !important');
          // },
          url: $scope.get_checklist_html_checklist_url,
          dataType: "json",
          cache: false,
          type: "GET",
          data: {},
          success: function (rs) {
            $timeout(function () {
              if (rs.succeed) {
                $scope.checklist_html = `${rs.data}`;
                toastr.success('HTML scraped successfully');
              } else {
                toastr.error(rs.message);
              }
            });
          },
        });
      }
    }
  ]);

}).call(this);