// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start();
require("turbolinks").start();
require("@rails/activestorage").start();
require("channels");

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

// Jquery
import jquery from 'jquery';
window.$ = window.jquery = jquery;
window.jQuery = window.jquery = jquery;

// Common Lib
import 'bootstrap';
import Chart from 'chart.js/auto'
import moment from 'moment';
import 'chartjs-adapter-moment';
import 'chartjs-adapter-moment';

global.toastr = require('toastr');
// global.bootbox = require('bootbox');
global.Chart = Chart;
global.moment = moment;

require('inputmask');
// require('@ckeditor/ckeditor5-alignment');
// require('select2');
// require('bootstrap-timepicker');

// require('social-share-button');

// Theme + Libraries
require('admin-lte');
// require.context('admin-lte/dist/img', true);
// require("packs/vendor/jquery.editable.min.js");
// require("packs/vendor/flipclock.min.js");
require("packs/vendor/jquery.floatingscroll.min.js");
require("packs/vendor/owl.carousel.min.js");
// require("packs/vendor/bootstrap-datepicker.js");
// require("packs/vendor/jquery.dataTables.min.js");
// require("packs/vendor/ckeditor.js");

// AngularJs
global.angular = require('angular');
require('angular-route');
require("packs/base/init");
require("packs/base/angular_app");
require("packs/base/angular_utils");

// AngularJs Controllers
global.app = angular.module('app');
require("packs/pages/dashboard_ctrl");
require("packs/pages/listing_ctrl");
require("packs/pages/raffle_ctrl");
require("packs/pages/follower_ctrl");
require("packs/pages/wax_tracker_ctrl");
require("packs/pages/box_ctrl");
require("packs/pages/search_page_ctrl");
require("packs/pages/bread_crumbs_filter");
require("packs/pages/menu_waxstat_ctrl");
require("packs/pages/pyt_ctrl");
require("packs/pages/checklist_ctrl");
require("packs/pages/price_history_ctrl");
require("packs/pages/sitemap_ctrl");