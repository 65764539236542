(function () {
  app.controller('BreadCrumbsFilter', [
    "$scope", '$timeout',
    function ($scope, $timeout) {
			$scope.page = 1;
      $scope.per_page = 12;
      $scope.box_type_ids = [];
      $scope.data = [];
      $scope.total = null;
      $scope.is_more = false;
      $scope.is_loading_data = true;
      $scope.is_loading_more = false;
      
			$scope.init = function () {
        $scope.getDataIndex();
      }

			$scope.getData = function () {
        var data = {
          page: $scope.page,
          per_page: $scope.per_page,
          sport: $scope.sport,
          year: $scope.year,
          brand: $scope.brand,
          box_type_ids: $scope.box_type_ids
        }
        $scope.is_loading_data = true;
        $.ajax({
          beforeSend: function(){
            $(".loading").hide();
            $(".loading").attr('style', 'background: rgba(0, 0, 0, 0)');
            $(".loading").attr('style', 'z-index: -1 !important');
          },
          url: $scope.wax_tracker_url,
          dataType: "json",
          cache: false,
          type: "GET",
          data: data,
          afterSend: function(){},
          success: function (rs) {
            $timeout(function () {
              $scope.is_loading_data = false;
              $scope.is_loading_more = false;
              if (rs.succeed) {
                $scope.total = rs.data.total;
                $scope.is_more = rs.data.is_more;
                if ($scope.data.length > 0) {
                  $scope.data = [...$scope.data,...rs.data.result];
                } else {
                  $scope.data = rs.data.result;
                }
              } else {
                $scope.data = [];
                $scope.is_more = false;
              }
            });
          }
        });
      };

      $scope.getDataIndex = function() {
        $scope.data = [];
        $scope.page = 1;
        $scope.getData();
      }

      $scope.filterData = function () {
        let temp = [];
        $.each($scope.filters, function(k, v) {
          temp = [...temp,...v.filter(b => b.checked).map(b => b.id)];
        });
        $scope.box_type_ids = temp;
        $scope.getDataIndex();
      }

      $scope.load_more = function() {
        $scope.is_loading_more = true;
        $scope.page = $scope.page + 1;
        $scope.getData();
      }
		}
	]);
}).call(this);