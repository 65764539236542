(function () {
  app.controller('SitemapCtrl', [
    "$scope", '$timeout',
    function ($scope, $timeout) {
      $scope.data_search = [];
      $scope.key_search = '';
      $scope.find_boxes = [];
      $scope.is_loading = false;


      $scope.init = function () {};

      $scope.getSuggestions = function () {
        $scope.is_loading = true;
        $.ajax({
          beforeSend: function(){
            $(".loading").hide();
            $(".loading").attr('style', 'background: rgba(0, 0, 0, 0)');
            $(".loading").attr('style', 'z-index: -1 !important');
          },
          url: $scope.search_name_box,
          dataType: "json",
          cache: false,
          type: "GET",
          data: { q: $scope.key_search },
          success: function (rs) {
            $timeout(function () {
              if (rs.succeed) {
                $scope.data_search = rs.data;
                $scope.data_search.forEach(d => {
                  d.hidden = false;
                });
              } else {
                toastr.error("An Unexpected Error Occurred!");
              }
              $scope.is_loading = false;
            });
          },
        })
      }

      $scope.close_menu = function (id) {
        $scope.key_search = '';
        $scope.data_search = [];
        $(`#content-filter-${id}`).removeClass('show');
      }

      $scope.open_menu = function (id) {
        $(`#content-filter-${id}`).addClass('show');
      }

      $scope.click_change = function (id) {
        if ($(`#content-filter-${id}`).hasClass('show')) {
          $scope.close_menu(id);
        } else {
          $('.dropdown-menu.dropdown-menu-right').removeClass('show');
          $scope.key_search = '';
          $scope.data_search = [];
          $scope.is_loading = false;
          $scope.open_menu(id);
        }
      }

      $scope.get_box_name = function (id) {
        let box = $scope.get_box(id)
        return box ? box.box_name : 'Select box';
      }

      $scope.select_box = function(v_id, box) {
        $scope.find_boxes.forEach(b => {
          if (b.id == v_id) {
            if (b.box_id != box.id) b.is_change = true;
            b.box_id = box.id;
            b.box_name = box.name
          }
        })
        $scope.close_menu(v_id);
      }

      $scope.get_box = function(id) {
        return $scope.find_boxes.filter(b => b.id == id)[0] || {}
      }

      $scope.save_box = function(id) {
        let box = $scope.get_box(id);
        
        if(!box) return;

        $.ajax({
          beforeSend: function(){
            // $(".loading").hide();
            // $(".loading").attr('style', 'background: rgba(0, 0, 0, 0)');
            // $(".loading").attr('style', 'z-index: -1 !important');
          },
          url: $scope.save_box_url.replace('-1', id),
          dataType: "json",
          cache: false,
          type: "POST",
          data: {
            box_id: box.box_id
          },
          afterSend: function(){},
          success: function (rs) {
            $timeout(function () {
              if (rs.succeed) {
                toastr.success('Updated successfully');
                $scope.find_boxes.forEach(b => {
                  if (b.id == id) {
                    b.is_change = false;
                  }
                })
              } else {
                toastr.error("An Unexpected Error Occurred!");
              }
            });
          }
        });
      }
    }
  ]);

}).call(this);