(function () {
  app.controller('PriceHistoryCtrl', [
    "$scope", '$timeout', '$compile', '$sce',
    function ($scope, $timeout, $compile, $sce) {
      $scope.wax_tracker_url = '';
      $scope.no_image_url = '';
      $scope.suggestions = [];
      $scope.chart_options = {
        "1_day": "1D",
        "7_days": "7D",
        "1_month": "1M",
        "3_months": "3M",
        "1_year": "1Y",
        "ytd": "YTD",
        "all": "All"
      };

      $scope.type_charts = [
        {key:'price_history_by_retailer', name:'Price history'},
        // {key:'3_month_by_retailer', name:'3-month price comparison'},
      ];

      $scope.borderColor = [
        '#59B788', '#AA23A5', '#DE6F1F', '#2369AA', '#3366cc',
        '#dc3912', '#ff9900', '#109618', '#990099', '#3b3eac',
        '#0099c6', '#66aa00', '#b82e2e', '#316395', '#22aa99',
        '#f00056', '#ae7000', '#177cb0', '#161823', '#c9dd22'
      ];



      $scope.init = function () {
      };
      

      $scope.getChart = function(key) {

        if ($scope.selected.type_chart_key == '3_month' || $scope.selected.type_chart_key == '3_month_by_retailer') {
          $scope.selected.selected_chart_option = '1_month';
        } else {
          $scope.selected.selected_chart_option = key;
          if (!($scope.selected.selected_chart_option in $scope.chart_options)) {
            $scope.selected.selected_chart_option = Object.keys($scope.chart_options)[0] ? Object.keys($scope.chart_options)[0] : $scope.selected.selected_chart_option;
          }
        }
        $scope.getChartData();
      }


      $scope.getRandomColor = function () {
        var letters = '0123456789ABCDEF',
            color = '#';
        for (var i = 0; i < 6; i++) {
          color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
      }

      $scope.getChartData = function () {
        let params = {
          selected_chart_option: $scope.selected.selected_chart_option,
          chart_type: $scope.selected.type_chart_key
        }

        if ($scope.source_name) {
          params.retailer = $scope.source_name;
        }

        if (!$scope.selected.type_chart_key) {
          $scope.selected.type_chart_key = 'price_history_by_retailer';
          params.chart_type = $scope.selected.type_chart_key;
        }

        $.ajax({
          // beforeSend: function(){
          //   $(".loading").hide();
          //   $(".loading").attr('style', 'background: rgba(0, 0, 0, 0)');
          //   $(".loading").attr('style', 'z-index: -1 !important');
          // },
          url: $scope.data_chart_url,
          dataType: "json",
          cache: false,
          type: "POST",
          data: params,
          success: function (rs) {
            $timeout(function () {
              if (rs.succeed) {
                rs.data = rs.data || [];
                for (let i = 0; i < rs.data.length; i++) {
                  let color = $scope.borderColor[i];
                  if (i > 19) color = $scope.getRandomColor();
                  rs.data[i].borderColor = color;
                  rs.data[i].backgroundColor = color;
                  rs.data[i].pointRadius = 0;
                  rs.data[i].pointHoverRadius = 0;
                  rs.data[i].hoverRadius = 0;
                  if ($scope.selected.type_chart_key == 'price_history_by_retailer') {
                    let ctx = document.getElementById('chart').getContext('2d');
                        gradient = ctx.createLinearGradient(0, 0, 0, 600);
                    gradient.addColorStop(0.1971, 'rgba(89, 183, 136, 0.5)');
                    gradient.addColorStop(0.9029, 'rgba(39, 81, 60, 0)');

                    rs.data[i].fill = true;
                    rs.data[i].backgroundColor = gradient;
                    rs.data[i].borderWidth = 1;
                  }
                }
                if ($scope.selected.type_chart_key == 'price_history_by_retailer') {
                  $('#legend-container').hide();
                } else {
                  $('#legend-container').show();
                }

                if ($scope.selected.type_chart_key == '3_month' || $scope.selected.type_chart_key == '3_month_by_retailer') {
                  $scope.chart.options.scales.x.ticks = {
                    callback: function(value, index, ticks) {
                      return value;
                    }
                  };
                  $scope.chart.options.scales.x.suggestedMin = 1;
                  $scope.chart.options.scales.x.suggestedMax = 31;
                } else {
                  $scope.chart.options.scales.x.ticks = {};
                  $scope.chart.options.scales.x.suggestedMin = null;
                  $scope.chart.options.scales.x.suggestedMax = null;
                }
                $scope.chart.data.datasets = rs.data;
              } else {
                $scope.chart.data.datasets = [];
                toastr.error("An Unexpected Error Occurred!");
              }
              $scope.chart.update();
            });
          },
        });
      }

      $scope.compileContents = function (contents) {
        $compile(contents.contents())($scope);
      }
    }
  ]);

}).call(this);
