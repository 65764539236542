(function () {
  app.controller('MenuWaxstatCtrl', [
    "$scope", '$timeout',
    function ($scope, $timeout) {
      $scope.load_browse_by_sport = true;
      $scope.data = [];
      $scope.sub_data = {};
      $scope.select_sport = null;
      $scope.is_open = false;

      $scope.init = function () {
        // $scope.getBrowseBySport();
      };

      $scope.getBrowseBySport = function () {
        $scope.load_browse_by_sport = true;
        $.ajax({
          // beforeSend: function(){
          //   $(".loading").hide();
          //   $(".loading").attr('style', 'background: rgba(0, 0, 0, 0)');
          //   $(".loading").attr('style', 'z-index: -1 !important');
          // },
          url: $scope.browse_by_sport_commons_path,
          dataType: "json",
          cache: false,
          type: "GET",
          data: {},
          success: function (rs) {
            $timeout(function () {
              $scope.load_browse_by_sport = false;
              if (rs.succeed) {
                $scope.data = rs.data;
              } else {
                $scope.data = [];
              }
            });
          },
        });
      };

      $scope.open_sub_menu = function (data) {
        $scope.sub_data = data;
        $('#sub-content-browse-by-sport').addClass('show');
      }

      $scope.open_menu = function () {
        $scope.is_open = true;
        $('#content-browse-by-sport').addClass('show');
      }

      $scope.close_menu = function () {
        $scope.is_open = false;
        $scope.sub_data = {};
        $('#content-browse-by-sport').removeClass('show');
        $('#sub-content-browse-by-sport').removeClass('show');
      }

      $scope.click_browse_sport = function () {
        $scope.is_open = !$scope.is_open;
        if ($scope.is_open) {
          $scope.open_menu();
        } else {
          $scope.close_menu();
        }
      }

      $scope.redirect_to = function (year) {
        location.href = location.origin + `/${year}-${$scope.sub_data.slug}-cards-release-calendar`;
        $scope.close_menu();
      }
    }]
  )
}).call(this);