(function () {
    app.controller('DashboardCtrl', [
        "$scope", '$timeout',
        function ($scope, $timeout) {

            $scope.init = function () {
            };
        }
    ]);

}).call(this);
