(function () {
  app.controller('FollowerCtrl', [
    "$scope", '$timeout',
    function ($scope, $timeout) {
      $scope.selected_followers = [];

      $scope.selectFollower = function(obj) {
        if(obj.currentTarget.checked) {
          addFollower(obj.currentTarget.value);
        } else {
          $('#selectAll').prop('checked', false);
          removeFollower(obj.currentTarget.value);
        }
        if ($("input[type=checkbox]:checked").length == ($("input[type=checkbox]").length - 1)) {
          $("#selectAll").prop("checked", true);
        }
        updateButton();
      };

      $scope.selectAll= function(obj) {
        $('input[type=checkbox]').prop('checked', obj.currentTarget.checked);
        if(!obj.currentTarget.checked) {
          $("input[type=checkbox]:not('#selectAll')").each(function() {
            removeFollower($(this).val());   
          });
        } else {
          $("input[type=checkbox]:not('#selectAll')").each(function() {
            addFollower($(this).val());
          });
        }
        updateButton();
      };

      function updateButton() {
        numberOfReceiver = $scope.selected_followers.length;
        button = $("#message-btn");
        if (numberOfReceiver <= 0) {
          button.html('Send Message');
        }
        else {
          button.html(`Send Message (${$scope.selected_followers.length})`);
        }
      }
    
      function addFollower(val){
        if (!$scope.selected_followers.includes(val)) {
          $scope.selected_followers.push(val);
        }
      }
    
      function removeFollower(val){
        if ($scope.selected_followers.includes(val)) {
          index = $scope.selected_followers.indexOf(val);
          $scope.selected_followers.splice(index, 1);
        }
      }

      $scope.init = function () {};
    }
  ]);

}).call(this);
