(function () {
    app.controller('RaffleCtrl', [
        "$scope", '$timeout',
        function ($scope) {
            $scope.step = 0;
            $scope.name = '';
            $scope.sport_type = '';
            $scope.league_select_status = false;
            $scope.league_select_status_for_auction = false;
            $scope.league_name = '';
            $scope.league_teams = [];
            $scope.team_params = [];
            $scope.msg_name_error = '';
            $scope.msg_sport_error = '';
            $scope.raffle_type = 'basic';
            $scope.display_highest_bidder = false;
            $scope.display_bid_history = false;
            $scope.currency_name = 'USD $';
            $scope.currency_sign = '$';

            $scope.init = function () {};

            $scope.previousStep = function () {
                if ($scope.step == 0) return;
                $scope.step -= 1;
            }

            $scope.nextStep = function () {
                if ($scope.step == 0) {
                    if (!$scope.validateName()) { return; }
                    $scope.getListingTeams();
                }

                $scope.bindingCkeditorContent();
                $scope.step += 1;
            }

            $scope.submit = function ($event) {
                $scope.msg_name_error = '';
                error = false;

                if ($.trim($scope.name) == '') {
                    $scope.msg_name_error = 'Name is required!';
                    error = true;
                }

                if ($.trim($scope.sport_type) == '' && $scope.raffle_type == 'basic') {
                    $scope.msg_sport_error = 'Sport type is required!';
                    error = true;
                }

                if(error) {
                    $event.preventDefault();
                    return false;
                }
            }

            $scope.filterValue = function($event){
                if ($event.keyCode === 32 ) { $event.preventDefault(); }
                if(isNaN(String.fromCharCode($event.keyCode))){ $event.preventDefault(); }
                if ($event.target.value > 99999 ) { $event.preventDefault(); }
            };

            $scope.bindingCkeditorContent = function () {
                angular.element('#ck-content-review').html('');
                let content = angular.element(".ck.ck-content.ck-editor__editable").clone();
                angular.element('#ck-content-review').html(content);
            }

            $scope.validateName = function () {
                $scope.msg_name_error = '';

                if ($.trim($scope.name) == '') {
                    $scope.msg_name_error = 'Name is required!';
                    return false;
                }
                return true;
            }

            $scope.selectedLeagueFunc = function() {
                if ($scope.leagueSelectValue > 0) {
                    $scope.league_select_status = true;
                } else {
                    $scope.league_select_status = false;
                }

                $scope.league_name = $("#league-selected-tag :selected").text();
            }

            $scope.selectedLeagueForAuctionFunc = function() {
                if ($scope.leagueSelectValueForAuction > 0 ) {
                    $scope.league_select_status_for_auction = true;
                } else {
                    $scope.league_select_status_for_auction = false;
                }

                $scope.league_name = $("#league-selected-tag-for-auction :selected").text();
            }


            $scope.getListingTeams = function () {
                $scope.league_teams  = [];
                if ($scope.raffle_type == 'basic') { return; }
                if ($scope.raffle_type == 'break by team') {  leagueSelectTagValue = $scope.leagueSelectValue; }
                if ($scope.raffle_type == 'auction') {  leagueSelectTagValue = $scope.leagueSelectValueForAuction; }
                
                $.get('/teams-of-league/' + parseInt(leagueSelectTagValue), {}, function (rs) {
                    if (rs.success) {
                        $scope.league_teams = rs.league_teams.length > 0 ? rs.league_teams : [];
                    } else {
                        $scope.league_teams = [];
                    }

                    if ($scope.raffle_type == 'break by team') {
                        $scope.league_teams = $scope.league_teams.map(lt => {
                            return {
                                sequence: lt.sequence,
                                team_id: lt.id,
                                league_id: lt.league_id,
                                name: lt.name,
                                price: lt.price,
                                puplic_note: lt.note
                            }
                        });
                    }

                    if ($scope.raffle_type == 'auction') {
                        $scope.league_teams = $scope.league_teams.map(lt => {
                            return {
                                sequence: lt.sequence,
                                team_id: lt.id,
                                league_id: lt.league_id,
                                name: lt.name,
                                price: 1,
                                puplic_note: lt.note
                            }
                        });
                    }

                    $scope.$apply();
                });
            }

            $scope.getRaffleTypeValue = function () {
                let raffleType = angular.element('input[name=raffleType]:checked').val();
                $scope.raffle_type = raffleType;

                angular.forEach(document.querySelectorAll('.unvisible'), function(value, key) {
                    angular.element(value).removeClass("show");
                    angular.element(value).removeClass("active");
                });

                if (raffleType == 'basic') {
                    angular.element("#basic-tab").addClass("active");
                    angular.element("#basic").addClass("show active");
                }

                if (raffleType == 'break by team') {
                    angular.element("#advance-tab").addClass("active");
                    $("#advance").addClass("show active");
                }

                if (raffleType == 'auction') {
                    angular.element("#auction-tab").addClass("active");
                    angular.element("#auction").addClass("show active");
                }


                $scope.display_bid_history = false;
                angular.element('#selectType').modal('hide');
            }

            $scope.changeRaffleTypeValue = function (type) {
                $scope.raffle_type = type;
                $scope.display_bid_history = false;
            }

            $scope.displayHighestBidderToggleFunc = function () {
                let value = angular.element('#display-highest-bidder').val();
                if (value == 'true') { $scope.display_bid_history = true; }
                if (value == 'false') { $scope.display_bid_history = false; }
            }

            $scope.selecteCurrencyFunc = function(e) {
                let value = angular.element('#currency').val();

                if ($scope.currency_type == 0) {
                    $scope.currency_name = 'USD $';
                    $scope.currency_sign = '$';
                }

                if ($scope.currency_type == 1) {
                    $scope.currency_name = 'EUR €';
                    $scope.currency_sign = '€';
                }

                if ($scope.currency_type == 2) {
                    $scope.currency_name = 'GBP £';
                    $scope.currency_sign = '£';
                }
            }
        }
    ]);

    app.controller('RaffleShowCtrl', [
        "$scope", '$timeout',
        function ($scope) {
            $scope.raffle_slug = '';
            $scope.league_teams = [];
            $scope.rowLength = '';
            $scope.init = function () {};

            $scope.getListingTeams = function () {
                $.get('/teams-of-league/' + parseInt($scope.leagueSelectValue), {}, function (rs) {
                    if (rs.success) {
                        let list_random_team = '';
                        rs.league_teams.forEach((ly, idx) => {
                            list_random_team += `${ly.name} \n`;
                        });
                        $scope.random_team_obj = list_random_team;
                        $scope.rowLength = parseInt(rs.team_length);
                    } else {
                        $scope.random_team_obj = "League don't have team";
                        $scope.rowLength = 4;
                    }

                    $scope.$apply();
                });
            }

            $scope.random_team = function(clear_form = false) {
                $.get(`/raffles/${$scope.raffle_id}/random_team?league_id=${$scope.leagueSelectValue}`, {clear_form: clear_form}, function (rs) {
                    if (rs.result == false && rs.notice == "Leguage don't have team") {
                        $scope.random_team_obj = "League don't have team";
                        $scope.rowLength = 4;
                        $scope.show_info_user_team = 'false';
                        toastr.error("League don't have team! Can't not random!");
                    }

                    if (rs.result == false && rs.notice == "Start over 2 time") {
                        $scope.show_info_user_team = 'false';
                        toastr.error("The list is already the default!");
                    }

                    if (rs.result == false && rs.notice == "This league not yet random team") {
                        $scope.show_info_user_team = 'false';
                        toastr.error("This league not yet random team!");
                    }

                    if (rs.result == true ) {
                        let list_random_team = '';
                        rs.list_team.forEach((ly, idx) => {
                            list_random_team += `${ly.name} \n`;
                        });
                        $scope.random_team_obj = list_random_team;
                        $scope.rowLength = parseInt(rs.team_length);
                        $scope.reload_user_random_team_info(rs);

                        if (rs.result && clear_form == false ) { toastr.success("Randomization is a success!");  }
                        if (rs.result && clear_form == true ) { toastr.success("Start Over with Slot # & Claim are success"); }
                    }
                    $scope.$apply();
                });
            }

            $scope.reload_user_random_team_info = function (rs) {
                $scope.lated_random_team_sequence = rs.info.sequence;
                $scope.lated_random_team_time = rs.info.time;
                $scope.team_username = rs.info.name_and_username;
                $scope.team_ip_address = rs.info.ip_address;
                $scope.show_info_user_team = 'true';
                $scope.get_history_claimer_arr = [];
            }

            $scope.getLatedRandomTeam = function () {
                $.get(`/raffles/${$scope.raffle_id}/get_lated_random_team?league_id=${$scope.leagueSelectValue}`, {}, function (rs) {
                    if (rs != false) {
                        $scope.reload_user_random_team_info(rs);
                        let list_random_team = '';
                        rs.list_team.forEach((ly, idx) => {
                            let name = ly.name;
                            list_random_team += `${name} \n`;
                        });
                        $scope.random_team_obj = list_random_team;
                        $scope.rowLength = parseInt(rs.list_team.length);
                        $scope.show_info_user_team = 'true';
                    } else {
                        $scope.getListingTeams();
                        $scope.show_info_user_team = 'false';
                    }
                    $scope.$apply();
                });
            }

            $scope.random_claimer = function (clear_form = false) {
                $scope.list_random  = [];
                $.get(`/raffles/${$scope.raffle_id}/random_claimer`, {clear_form: clear_form}, function (rs) {
                    if (rs.result) {
                        let list_random = '';
                        rs.list_claimer.forEach((ly, idx) => {
                            let sequence = ly.sequence;
                            let full_name = ly.full_name;
                            let username = `(${ly.username})`;
                            if (full_name == null) { full_name = ' '; }
                            if (ly.username == null) { username = ' '; }
                            list_random += `${sequence}: ${full_name} ${username}\n`;
                        });
                        $scope.raffle_claim_obj = list_random;
                        $scope.reload_user_random_claimer_info(rs);
                        $scope.$apply();
                    }

                    if (rs.result && clear_form == false ) { toastr.success("Randomization is a success!");  }
                    if (rs.result && clear_form == true ) { toastr.success("Start Over with Slot # & Claim are success");  }
                    if (rs.result == false && rs.notice == 'Start over 2 time') { toastr.error("The list is already the default!"); };
                    if (rs.result == false && rs.notice == 'This list not yet random claimer') { toastr.error("This list not yet random claimer!"); };
                });
            }

            $scope.sent_get_history_request = function () {
                $scope.get_history(type = 'team');
                $scope.get_history();
            }

            $scope.get_history = function ( type = 'claimer' ) {
                $.get(`/raffles/${$scope.raffle_id}/get_history_random`, { type: type }, function (rs) {
                    if (rs) {
                        if (type == 'claimer') {
                            $scope.get_history_claimer_arr = rs.data;
                            $scope.get_history_claimer_arr = $scope.get_history_claimer_arr.map(lt => {
                                return {
                                    sequence: lt.attributes.sequence,
                                    time: lt.attributes.time,
                                    date: lt.attributes.date,
                                    full_name: lt.attributes.full_name,
                                    username: lt.attributes.username,
                                    ip_address: lt.attributes.ip_address,
                                    list_reset: lt.attributes.list_reset,
                                    mask_full_name: lt.attributes.mask_full_name,
                                    raffle_claim_obj: JSON.parse(lt.attributes.raffle_claim_obj)
                                }
                            });
                        } else {
                            $scope.get_history_team_arr = rs.data;
                            $scope.get_history_team_arr = $scope.get_history_team_arr.map(lt => {
                                return {
                                    league_name: lt.attributes.league_name,
                                    sequence: lt.attributes.sequence,
                                    time: lt.attributes.time,
                                    date: lt.attributes.date,
                                    full_name: lt.attributes.full_name,
                                    username: lt.attributes.username,
                                    ip_address: lt.attributes.ip_address,
                                    list_reset: lt.attributes.list_reset,
                                    mask_full_name: lt.attributes.mask_full_name,
                                    team_obj: JSON.parse(lt.attributes.team_obj)
                                }
                            });

                        }

                        $scope.$apply();
                    } else {
                        toastr.error("Error, please try again!")
                    }
                });
            }

            $scope.reload_user_random_claimer_info = function (rs) {
                $scope.lated_random_sequence = rs.info.sequence;
                $scope.lated_random_time = rs.info.time;
                $scope.username = rs.info.name_and_username;
                $scope.ip_address = rs.info.ip_address;
                $scope.show_info_user_claimer = 'true';
            }

            $scope.getInfoDiceTab = function () {
                $scope.team_name_lists = [];
                let team_list = angular.fromJson($scope.team_lists_default);
                angular.forEach(team_list, function(value, key) {
                    team_name = value.length > 0 ? value : ' ';
                    $scope.team_name_lists.push({sequence: key, team_name:  team_name});
                })

                $scope.claimer_name_list = [];
                let claim_lists = angular.fromJson($scope.claimer_lists_default);
                angular.forEach(claim_lists, function(value, key) {
                    username = value.length > 0 ? value : ' ';
                    $scope.claimer_name_list.push({sequence: key, username:  username});
                })
            }

            $scope.randomDice = function (type = nil) {
                if ( type == 'team') { 
                    $scope.teams_random_times += 1;
                    let object = $scope.team_name_lists;
                    let length =  $scope.team_name_lists.length;
                    $scope.team_name_lists = _.sampleSize(object, length);
                };

                if ( type == 'claimer') { 
                    $scope.claimer_random_times += 1;
                    let object = $scope.claimer_name_list;
                    let length =  $scope.claimer_name_list.length;
                    $scope.claimer_name_list = _.sampleSize(object, length);
                };
            }

            $scope.generateCombinedView = function (owner = true) {
                var result = [];

                if (owner) {
                    let claimer_split = $scope.raffle_claim_obj.split('\n');
                    let team_split = $scope.random_team_obj.split('\n');

                    if (team_split.length > claimer_split.length) {
                        var max_lenght = team_split.length;
                    } else {
                        var max_lenght = claimer_split.length;
                    }

                    for (let i = 0; i < max_lenght - 1; i++) {
                        let temp_claimer = claimer_split[i] == null ? '' : claimer_split[i];
                        let temp_team = team_split[i] == null ? '' : team_split[i];
                        result[i] = {claimer: temp_claimer, team: temp_team};
                    }
                } else {
                    let claimer_split = $scope.get_history_claimer_arr[0].raffle_claim_obj;
                    let team_split = $scope.random_team_obj.split('\n');

                    if (team_split.length > Object.keys(claimer_split).length) {
                        var max_lenght = team_split.length;
                    } else {
                        var max_lenght = Object.keys(claimer_split).length;
                    }

                    for (let i = 0; i < max_lenght - 1; i++) {

                        let temp_claimer = claimer_split[i] == null ? '' : claimer_split[i];
                        let temp_team = team_split[i] == null ? '' : team_split[i];
                        result[i] = {
                            sequence: temp_claimer.sequence,
                            full_name: temp_claimer.full_name,
                            full_name_mark: temp_claimer.full_name_mark,
                            username: temp_claimer.username,
                            team: temp_team
                        };
                    }
                }
                $scope.combined_view = result;
            }
        }
    ]);
}).call(this);
