(function () {
  app.controller('BoxCtrl', [
    "$scope", '$timeout',
    function ($scope, $timeout) {
      $scope.wax_tracker_url = '';
      $scope.no_image_url = '';
      $scope.suggestions = [];
      $scope.is_visible_suggestions = false;
      $scope.current_focus = -1;
      $scope.all_results = false;
      $scope.current_tab = 'prices';
      $scope.chart_options = {
        "1_day": "1D",
        "7_days": "7D",
        "1_month": "1M",
        "3_months": "3M",
        "1_year": "1Y",
        "ytd": "YTD",
        "all": "All"
      };

      $scope.chart_market_options = {
        "7_days": "7D",
        "1_month": "1M",
        "3_months": "3M",
        "1_year": "1Y",
        "ytd": "YTD",
        "all": "All"
      };

      $scope.type_charts = [
        {key:'price', name:'Average price history'},
        {key:'price_all_store', name:'Price history by retailer'},
        // {key:'3_month', name:'3-month price comparison'},
      ];

      $scope.type_chart_key = 'price';
      $scope.loading_more_boxes = true;
      $scope.borderColor = [
        '#59B788', '#AA23A5', '#DE6F1F', '#2369AA', '#3366cc',
        '#dc3912', '#ff9900', '#109618', '#990099', '#3b3eac',
        '#0099c6', '#66aa00', '#b82e2e', '#316395', '#22aa99',
        '#f00056', '#ae7000', '#177cb0', '#161823', '#c9dd22'
      ];

      $scope.data_like_box = [];
      $scope.more_boxes = [];
      $scope.market_buy = {
        data: [],
        page: 1,
        total_page: 0
      }
      $scope.market_sell = {
        data: [],
        page: 1,
        total_page: 0
      }
      $scope.market_trades = {
        data: [],
        page: 1,
        total_page: 0
      }
      $scope.market_page_size = 10;
      $scope.import_url = {
        steel_city_collectibles_url: '',
        blowout_cards_url: '',
        dave_and_adams_url: '',
        legends_fan_shop_url: '',
        the_sports_card_zone_url: '',
        roadshow_cards_url: '',
        piece_of_the_game_url: '',
        the_cardiacs_url: '',
        rbicru7_url: '',
        diamond_cards_online_url: '',
        giant_sports_cards_url: '',
        chicagoland_sports_cards_url: '',
        silver_state_hobby_url: '',
        cherry_collectibles_url: '',
        full_fund_dave_and_adams: true,
        full_fund_steel_city_collectibles: false
      };

      $scope.import_retailers = [
        {name: "Steel City Collectibles", value: "steel_city_collectibles_url"},
        {name: "Blowout Cards", value: "blowout_cards_url"},
        {name: "Dave & Adams", value: "dave_and_adams_url"}
      ]
      $scope.import_retailer = {
        url: "",
        choose_retailer: "dave_and_adams_url"
      };
      $scope.init = function () {};
      
      $scope.getSuggestions = function () {
        let except_ids = $scope.data_like_box.map(d => d.id);
        $.ajax({
          beforeSend: function(){
            $(".loading").hide();
            $(".loading").attr('style', 'background: rgba(0, 0, 0, 0)');
            $(".loading").attr('style', 'z-index: -1 !important');
          },
          url: $scope.search_name_box,
          dataType: "json",
          cache: false,
          type: "GET",
          data: { q: $scope.key_search, except_ids: [...except_ids,...[$scope.box_id]] },
          success: function (rs) {
            $timeout(function () {
              if (rs.succeed) {
                $scope.data_search = rs.data;
                $scope.data_search.forEach(d => {
                  d.hidden = false;
                });
              } else {
                toastr.error("An Unexpected Error Occurred!");
              }
            });
          },
        })
      }

      $scope.add_like_box = function (data) {
        $scope.data_like_box = $scope.data_like_box.filter(b => b.id != data.id);
        $scope.data_like_box.push(data);
        $scope.data_search.forEach(d => {
          if (d.id == data.id) {
            d.hidden = true;
          }
        });
        $('#same_box_ids').val($scope.data_like_box.map(d => d.id));
      }

      $scope.remove_like_box = function (data) {
        $scope.data_like_box = $scope.data_like_box.filter(b => b.id != data.id);
        if ($scope.data_search) {
          $scope.data_search.forEach(d => {
            if (d.id == data.id) {
              d.hidden = false;
            }
          });
        }
        $('#same_box_ids').val($scope.data_like_box.map(d => d.id));
      }

      $scope.getChart = function(key) {
        if ($scope.type_chart_key == '3_month' || $scope.type_chart_key == '3_month_by_retailer') {
          $scope.selected_chart_option = '1_month';
        } else {
          $scope.selected_chart_option = key;
          if (!($scope.selected_chart_option in $scope.chart_options)) {
            $scope.selected_chart_option = Object.keys($scope.chart_options)[0] ? Object.keys($scope.chart_options)[0] : $scope.selected_chart_option;
          }
        }
        $scope.getChartData();
      }

      $scope.getChartMarket = function(key, type) {
        switch (type) {
          case 'offers':
            $scope.selected_chart_market_offers_option = key;
            break;
          case 'trades':
            $scope.selected_chart_market_trades_option = key;
            break;
          default:
            return;
        }
        $scope.getChartMarketData(type);
      }

      $scope.getRandomColor = function () {
        var letters = '0123456789ABCDEF',
            color = '#';
        for (var i = 0; i < 6; i++) {
          color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
      }

      $scope.getChartData = function () {
        let params = {
          selected_chart_option: $scope.selected_chart_option,
          chart_type: $scope.type_chart_key
        }

        if ($scope.source_name) {
          params.retailer = $scope.source_name;
        }

        $.ajax({
          // beforeSend: function(){
          //   $(".loading").hide();
          //   $(".loading").attr('style', 'background: rgba(0, 0, 0, 0)');
          //   $(".loading").attr('style', 'z-index: -1 !important');
          // },
          url: $scope.data_chart_url,
          dataType: "json",
          cache: false,
          type: "POST",
          data: params,
          success: function (rs) {
            $timeout(function () {
              if (rs.succeed) {
                rs.data = rs.data || [];
                for (let i = 0; i < rs.data.length; i++) {
                  let color = $scope.borderColor[i];
                  if (i > 19) color = $scope.getRandomColor();
                  rs.data[i].borderColor = color;
                  rs.data[i].backgroundColor = color;
                  rs.data[i].pointRadius = 0;
                  rs.data[i].pointHoverRadius = 0;
                  rs.data[i].hoverRadius = 0;
                  if ($scope.type_chart_key == 'price') {
                    let ctx = document.getElementById('chart').getContext('2d');
                        gradient = ctx.createLinearGradient(0, 0, 0, 400);
                    gradient.addColorStop(0.1971, 'rgba(89, 183, 136, 0.5)');
                    gradient.addColorStop(0.9029, 'rgba(39, 81, 60, 0)');
                    rs.data[i].fill = true;
                    rs.data[i].backgroundColor = gradient;
                    rs.data[i].borderWidth = 1;
                  }
                }
                if ($scope.type_chart_key == 'price') {
                  $('#legend-container').hide();
                } else {
                  $('#legend-container').show();
                }

                if ($scope.type_chart_key == '3_month' || $scope.type_chart_key == '3_month_by_retailer') {
                  $scope.chart.options.scales.x.ticks = {
                    callback: function(value, index, ticks) {
                      return value;
                    }
                  };
                  // $scope.chart.options.plugins.tooltip = {
                  //     callbacks: {
                  //       title: function(context) {
                  //         return context[0].raw.date;
                  //       },
                  //       label: function(context) {
                  //         return context.parsed.y;
                  //       }
                  //   }
                  // };
                  // $scope.chart.options.scales.x.time.tooltipFormat = 'MMM DD, yyyy';
                  $scope.chart.options.scales.x.suggestedMin = 1;
                  $scope.chart.options.scales.x.suggestedMax = 31;
                } else {
                  $scope.chart.options.scales.x.ticks = {};
                  // $scope.chart.options.scales.x.time.tooltipFormat = 'MMM DD, yyyy, hh:mm:ss a';
                  // $scope.chart.options.plugins.tooltip = {
                  //   callbacks: {}
                  // };
                  $scope.chart.options.scales.x.suggestedMin = null;
                  $scope.chart.options.scales.x.suggestedMax = null;
                }
                $scope.chart.data.datasets = rs.data;
              } else {
                $scope.chart.data.datasets = [];
                toastr.error("An Unexpected Error Occurred!");
              }
              $scope.chart.update();
            });
          },
        });
      }

      $scope.getChartMarketData = function (type) {
        var params = {};
        switch (type) {
          case 'offers':
            params = {
              selected_chart_option: $scope.selected_chart_market_offers_option,
              type: type
            }
            break;
          case 'trades':
            params = {
              selected_chart_option: $scope.selected_chart_market_trades_option,
              type: type
            }
            break;
          default:
            return;
        }

        $.ajax({
          // beforeSend: function(){
          //   $(".loading").hide();
          //   $(".loading").attr('style', 'background: rgba(0, 0, 0, 0)');
          //   $(".loading").attr('style', 'z-index: -1 !important');
          // },
          url: $scope.data_chart_market_url,
          dataType: "json",
          cache: false,
          type: "GET",
          data: params,
          success: function (rs) {
            $timeout(function () {
              if (rs.succeed) {
                rs.data = rs.data || [];
                for (let i = 0; i < rs.data.length; i++) {
                  let color = $scope.borderColor[i];
                  if (i > 19) color = $scope.getRandomColor();

                  rs.data[i].borderColor = color;
                  rs.data[i].backgroundColor = color;
                }

                switch (type) {
                  case 'offers':
                    $scope.chart_offers.options.scales.x.ticks = {};
                    $scope.chart_offers.options.scales.x.time.tooltipFormat = 'MMM DD, yyyy, hh:mm:ss a';
                    $scope.chart_offers.options.plugins.tooltip = {
                      callbacks: {}
                    };
                    $scope.chart_offers.options.scales.x.suggestedMin = null;
                    $scope.chart_offers.options.scales.x.suggestedMax = null;
                    $scope.chart_offers.data.datasets = rs.data;
                    break;
                  case 'trades':
                    $scope.chart_trades.options.scales.x.ticks = {};
                    $scope.chart_trades.options.scales.x.time.tooltipFormat = 'MMM DD, yyyy, hh:mm:ss a';
                    $scope.chart_trades.options.plugins.tooltip = {
                      callbacks: {}
                    };
                    $scope.chart_trades.options.scales.x.suggestedMin = null;
                    $scope.chart_trades.options.scales.x.suggestedMax = null;
                    $scope.chart_trades.data.datasets = rs.data;
                    break;
                }
              } else {
                switch (key) {
                  case 'offers':
                    $scope.chart_offers.data.datasets = [];
                    break;
                  case 'trades':
                    $scope.chart_trades.data.datasets = [];
                    break;
                  default:
                    break;
                }
                toastr.error("An Unexpected Error Occurred!");
              }
              $scope.chart_offers.update();
              $scope.chart_trades.update();
            });
          },
        });
      }

      $scope.getMoreBoxes = function () {
        $scope.loading_more_boxes = true;
        $.ajax({
          // beforeSend: function(){
          //   $(".loading").hide();
          //   $(".loading").attr('style', 'background: rgba(0, 0, 0, 0)');
          //   $(".loading").attr('style', 'z-index: -1 !important');
          // },
          url: $scope.more_boxes_url,
          dataType: "json",
          cache: false,
          type: "GET",
          data: {},
          success: function (rs) {
            $timeout(function () {
              $scope.loading_more_boxes = false;
              if (rs.succeed) {
                $scope.more_boxes = rs.data;
                $scope.number_page_more_boxes = Math.ceil($scope.more_boxes.length / 3);
                setTimeout(() => {
                  $scope.chart_more_box($scope.more_boxes);
                }, 400);
              } else {
                $scope.more_boxes = [];
                toastr.error("An Unexpected Error Occurred!");
              }
            });
          },
        });
      }

      $scope.chart_more_box = function () {
        $scope.more_boxes.forEach(box => {
          if (box == {} || box.is_ad) {
            return;
          }
          let data = box.chart.chart_data[0].data.sort().map(row => ({ x: row[0], y: row[1] }));
        
          new Chart(`chart-more-box-${box.id}`,{
            type: 'line',
            data: {
              datasets: [
                {
                  data: data,
                  borderColor: box.chart.color,
                  pointRadius: 0,
                  poitnHoverRadius: 0,
                  hoverRadius: 0
                }
              ]
            },
            options: {
              animation: false,
              plugins: {
                legend: {
                  display: false,
                },
                tooltip: {
                  enabled: false
                }
              },
              scales: {
                x: {
                  display: false
                },
                y: {
                  display: false
                }
              }
            }
          });
        });
      }

      $scope.getStatisticOutOfStock = function () {
        $.ajax({
          // beforeSend: function(){
          //   $(".loading").hide();
          //   $(".loading").attr('style', 'background: rgba(0, 0, 0, 0)');
          //   $(".loading").attr('style', 'z-index: -1 !important');
          // },
          url: $scope.statistic_out_of_stock_url,
          dataType: "json",
          cache: false,
          type: "GET",
          data: {},
          success: function (rs) {
            $timeout(function () {
              if (rs.succeed) {
                $scope.statistic_out_of_stock = rs.data;
              } else {
                $scope.statistic_out_of_stock = {
                  one_day: null,
                  seven_days: null,
                  thirty_days: null,
                  one_year: null
                };
                toastr.error("An Unexpected Error Occurred!");
              }
            });
          },
        });
      }

      $scope.changeTab = function(tab) {
        $scope.current_tab = tab;
      }

      $scope.changeCardBreakPrice = function (type) {
        let price = Number($scope[type]);
        if ($scope[type] == '') return;
        if (isNaN(price) || price < 0) price = '';
        $scope[type] = price;
      }
      
      $scope.totalCardBreakPrice = function () {
        let price_pay = $scope.price_pay ? (Number($scope.price_pay) > 0 ? Number($scope.price_pay) : 0 ): 0,
            price_profit = $scope.price_profit ? (Number($scope.price_profit) > 0 ? Number($scope.price_profit) : 0 ): 0;
        $scope.total_card_break_price = (price_pay + price_profit).toFixed(0);
      }

      $scope.getPriceMarket = function (type) {
        page = 1
        switch (type) {
          case 'buy':
            page = $scope.market_buy.page;
            break;
          case 'sell':
            page = $scope.market_sell.page;
            break;
          case 'trades':
            page = $scope.market_trades.page;
            break;
        }
        $.ajax({
          // beforeSend: function(){
          //   $(".loading").hide();
          //   $(".loading").attr('style', 'background: rgba(0, 0, 0, 0)');
          //   $(".loading").attr('style', 'z-index: -1 !important');
          // },
          url: $scope.market_data_box_url,
          dataType: "json",
          cache: false,
          type: "GET",
          data: {
            page: page,
            type: type
          },
          success: function (rs) {
            $timeout(function () {
              if (rs.succeed) {
                let count_page = Math.ceil(rs.data.total / $scope.market_page_size);
                switch (type) {
                  case 'buy':
                    $scope.market_buy.data = rs.data.records;
                    $scope.market_buy.total_page = count_page;
                    break;
                  case 'sell':
                    $scope.market_sell.data = rs.data.records;
                    $scope.market_sell.total_page = count_page;
                    break;
                  case 'trades':
                    $scope.market_trades.data = rs.data.records;
                    $scope.market_trades.total_page = count_page;
                    break;
                }
              } else {
                toastr.error("An Unexpected Error Occurred!");
              }
            });
          },
        });
      }

      $scope.changePageMarket = function (page, type) {
        if (page < 1) return;
        switch (type) {
          case 'buy':
            $scope.market_buy.page = page;
            break;
          case 'sell':
            $scope.market_sell.page = page;
            break;
          case 'trades':
            $scope.market_trades.page = page;
            break;
        }
        $scope.getPriceMarket(type)
      }

      $scope.nextCreateStep = async function () {
        if ($scope.disabledNextCreateStep()) return;
        await $scope.getDataNewBox();
        $scope.current_step = 2;
      }

      $scope.checkImportRetailerUrl = function () {
        let check = false;
        switch ($scope.import_retailer.choose_retailer) {
          case "steel_city_collectibles_url":
            check = !$scope.checkRegex('steel_city_collectibles_url', true);
            break;
          case "blowout_cards_url":
            check = !$scope.checkRegex('blowout_cards_url', true);
            break;
          case "dave_and_adams_url":
            check = !$scope.checkRegex('dave_and_adams_url', true);
            break;
        }
        return check;
      }

      $scope.getName = function() {
        let retailer = $scope.import_retailers.filter(r => r.value == $scope.import_retailer.choose_retailer)[0] || {};
        return retailer.name || '';
      }

      $scope.disabledNextCreateStep = function () {
        return $scope.import_retailer.url == '' || $scope.checkImportRetailerUrl();
      }

      $scope.checkRegex = function (type, is_import = false) {
        let url = '';
        switch (type) {
          case 'steel_city_collectibles_url':
            url = is_import ? $scope.import_retailer.url : $scope.import_url.steel_city_collectibles_url;
            if (url == '') return true;
            return /^(http|https):\/\/(\www\.)?steelcitycollectibles\.com\/(?:\w)*./i.test(url);
          case 'blowout_cards_url':
            url = is_import ? $scope.import_retailer.url : $scope.import_url.blowout_cards_url;
            if (url == '') return true;
            return /^(http|https):\/\/(\www\.)?blowoutcards\.com\/(?:\w)*./i.test(url);
          case 'dave_and_adams_url':
            url = is_import ? $scope.import_retailer.url : $scope.import_url.dave_and_adams_url;
            if (url == '') return true;
            return /^(http|https):\/\/(\www\.)?dacardworld\.com\/(?:\w)*./i.test(url);
          case 'legends_fan_shop_url':
            if ($scope.import_url.legends_fan_shop_url == '') return true;
            return /^(http|https):\/\/(\www\.)?legendsfanshop\.com\/(?:\w)*./i.test($scope.import_url.legends_fan_shop_url);
          case 'the_sports_card_zone_url':
            if ($scope.import_url.the_sports_card_zone_url == '') return true;
            return /^(http|https):\/\/(\www\.)?thesportscardzone\.com\/(?:\w)*./i.test($scope.import_url.the_sports_card_zone_url);
          case 'roadshow_cards_url':
            if ($scope.import_url.roadshow_cards_url == '') return true;
            return /^(http|https):\/\/(\www\.)?roadshowcards\.com\/(?:\w)*./i.test($scope.import_url.roadshow_cards_url);
          case 'piece_of_the_game_url':
            if ($scope.import_url.piece_of_the_game_url == '') return true;
            return /^(http|https):\/\/(\www\.)?shoppieceofthegame\.com\/(?:\w)*./i.test($scope.import_url.piece_of_the_game_url);
          case 'the_cardiacs_url':
            if ($scope.import_url.the_cardiacs_url == '') return true;
            return /^(http|https):\/\/(\www\.)?thecardiacs\.com\/(?:\w)*./i.test($scope.import_url.the_cardiacs_url);
          case 'rbicru7_url':
            if ($scope.import_url.rbicru7_url == '') return true;
            return /^(http|https):\/\/(\www\.)?rbicru7\.com\/(?:\w)*./i.test($scope.import_url.rbicru7_url);
          case 'diamond_cards_online_url':
            if ($scope.import_url.diamond_cards_online_url == '') return true;
            return /^(http|https):\/\/(\www\.)?diamondcardsonline\.com\/(?:\w)*./i.test($scope.import_url.diamond_cards_online_url);
          case 'giant_sports_cards_url':
            if ($scope.import_url.giant_sports_cards_url == '') return true;
            return /^(http|https):\/\/(\www\.)?giantsportscards\.com\/(?:\w)*./i.test($scope.import_url.giant_sports_cards_url);
          case 'chicagoland_sports_cards_url':
            if ($scope.import_url.chicagoland_sports_cards_url == '') return true;
            return /^(http|https):\/\/(\www\.)?chicagolandsportscards\.com\/(?:\w)*./i.test($scope.import_url.chicagoland_sports_cards_url);
          case 'silver_state_hobby_url':
            if ($scope.import_url.silver_state_hobby_url == '') return true;
            return /^(http|https):\/\/(\www\.)?silverstatehobby\.com\/(?:\w)*./i.test($scope.import_url.silver_state_hobby_url);
          case 'cherry_collectibles_url':
            if ($scope.import_url.cherry_collectibles_url == '') return true;
            return /^(http|https):\/\/(\www\.)?cherrycollectables\.com\.au\/(?:\w)*./i.test($scope.import_url.cherry_collectibles_url);
          default:
            return false;
        }
      }

      $scope.getDataNewBox = function () {
        $.ajax({
          // beforeSend: function(){
          //   $(".loading").hide();
          //   $(".loading").attr('style', 'background: rgba(0, 0, 0, 0)');
          //   $(".loading").attr('style', 'z-index: -1 !important');
          // },
          url: $scope.get_data_new_box_url,
          dataType: "json",
          cache: false,
          type: "GET",
          data: {
            url: $scope.import_retailer.url,
            retailer: $scope.import_retailer.choose_retailer
          },
          success: function (rs) {
            $timeout(function () {
              if (rs.succeed) {
                if ($scope.upcs.length > 0 && rs.data && rs.data.upc) {
                  $scope.upcs.push(rs.data.upc);
                }
                $scope.data_new_box = rs.data;
                if ($scope.data_new_box.release_date) {
                  window.applyDatepicker($scope.data_new_box.release_date);
                }
                $scope.addLinkNewBox($scope.data_new_box.slug);

                // remove description
                $scope.data_new_box.description = '';
                window.editor.setData('');
                // window.editor.setData($scope.data_new_box.description);
              } else {
                toastr.error(rs.message);
              }
            });
          },
        });
      }

      $scope.backStepNewBox = function () {
        $scope.current_step = 1;
      }

      $scope.addLinkNewBox = function (slug) {
        $.each($scope.import_url, function(index, value) {
          switch (index) {
            case 'steel_city_collectibles_url':
              $scope.import_url.steel_city_collectibles_url = `https://www.steelcitycollectibles.com/i/${slug}`;
              break;
            case 'blowout_cards_url':
              $scope.import_url.blowout_cards_url = `https://www.blowoutcards.com/${slug}.html`;
              break;
            case 'dave_and_adams_url':
              $scope.import_url.dave_and_adams_url = `https://www.dacardworld.com/sports-cards/${slug}`;
              break;
            // case 'legends_fan_shop_url':
            //   $scope.import_url.legends_fan_shop_url = `https://www.legendsfanshop.com/${slug}`;
            //   break;
            // case 'the_sports_card_zone_url':
            //   $scope.import_url.the_sports_card_zone_url = `https://thesportscardzone.com/products/${slug}`;
            //   break;
            // case 'roadshow_cards_url':
            //   $scope.import_url.roadshow_cards_url = `https://roadshowcards.com/products/${slug}`;
            //   break;
            // case 'piece_of_the_game_url':
            //   $scope.import_url.piece_of_the_game_url = `https://shoppieceofthegame.com/products/${slug}`;
            //   break;
            // case 'the_cardiacs_url':
            //   $scope.import_url.the_cardiacs_url = `https://www.thecardiacs.com/products/${slug}`;
            //   break;
            // case 'rbicru7_url':
            //   $scope.import_url.rbicru7_url = `https://rbicru7.com/products/${slug}`;
            //   break;
            // case 'diamond_cards_online_url':
            //   $scope.import_url.diamond_cards_online_url = `https://www.diamondcardsonline.com/${slug}`;
            //   break;
            // case 'giant_sports_cards_url':
            //   $scope.import_url.giant_sports_cards_url = `https://giantsportscards.com/products/${slug}`;
            //   break;
            // case 'chicagoland_sports_cards_url':
            //   $scope.import_url.chicagoland_sports_cards_url = `https://www.chicagolandsportscards.com/${slug}.html`;
            //   break;
            // case 'silver_state_hobby_url':
            //   $scope.import_url.silver_state_hobby_url = `https://silverstatehobby.com/products/${slug}`;
            //   break;
            // case 'cherry_collectibles_url':
            //   $scope.import_url.cherry_collectibles_url = `https://www.cherrycollectables.com.au/products/${slug}`;
            //   break;
            default:
              break;
          }
      }); 
      }

      $scope.submitCreateBox = function() {
        var is_format = true,
            check_empty = false;

        $.each($scope.import_url, function(key, value) {
          if (key.includes('_url')) {
            let check_regex = $scope.checkRegex(key);
            if (!check_regex && is_format) is_format = false;
            if (!!value && !check_empty) check_empty = true;
          }
        })

        if (!is_format || !check_empty) return;

        if (
            !$scope.data_new_box.name || 
            !$scope.data_new_box.sport_type_id || 
            !$scope.data_new_box.league_year_id || 
            !$scope.data_new_box.box_type_id || 
            !$scope.data_new_box.name
          ) return;
        $scope.data_new_box.description = window.editor.getData();
        var box = {...$scope.data_new_box, ...$scope.import_url};

        box.release_date = $('#datepicker-hidden').val();
        box.upcs = $scope.upcs;

        $.ajax({
          // beforeSend: function(){
          //   $(".loading").hide();
          //   $(".loading").attr('style', 'background: rgba(0, 0, 0, 0)');
          //   $(".loading").attr('style', 'z-index: -1 !important');
          // },
          url: $scope.create_box_url,
          dataType: "json",
          cache: false,
          type: "POST",
          data: {
            box: box,
            is_ajax: true
          },
          success: function (rs) {
            $timeout(function () {
              if (rs.succeed) {
                window.location.pathname = rs.href;
              } else {
                toastr.error(rs.message);
              }
            });
          },
        });
      }

      $scope.addUpc = function () {
        $scope.upcs.push('');
      }

      $scope.checkUPC = function (upc, index) {
        if (!upc) return;
        $scope.upcs[index] = upc.replaceAll(" ","");
        $.ajax({
          // beforeSend: function(){
          //   $(".loading").hide();
          //   $(".loading").attr('style', 'background: rgba(0, 0, 0, 0)');
          //   $(".loading").attr('style', 'z-index: -1 !important');
          // },
          url: $scope.check_upc_boxes_url,
          dataType: "json",
          cache: false,
          type: "GET",
          data: {
            upc: upc
          },
          success: function (rs) {
            $timeout(function () {
              if (rs.succeed) {
                var divs = document.querySelectorAll('#upcs div'),
                    element = divs[index];

                if (rs.data.length > 0) {
                  var html = "<p>Boxes have the same UPC:";
                  rs.data.forEach(b => {
                    html+= `<br><a class="ml-3" href="${b.slug}" target="_blank">${b.name}</a>`;
                  })
                  html+="</p>";
                  element.innerHTML = html;
                } else {
                  element.innerHTML = "";
                }
                
              } else {
                toastr.error(rs.message);
              }
            });
          },
        });
      }
    }
  ]);

}).call(this);
