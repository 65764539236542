(function() {
    var app = angular.module('app',[]);

    $(document).on('turbolinks:load', function () {
        angular.bootstrap(document.body, ['app']);
    });

    app.filter('maskString', function () {
        return function (str) {
            if (!str) { return '******'; }

            return str.replace(/./g, '*').trim();
        };
    });

    app.filter('unsafe', function($sce) {
        return function(val) {
            return $sce.trustAsHtml(val);
        };
    });

    app.filter('formatPhone', function () {
        return function (tel) {
            if (!tel) { return ''; }

            var value = tel.toString().trim().replace(/^\+/, '');

            if (value.match(/[^0-9]/)) {
                return tel;
            }

            var country, city, number;

            switch (value.length) {
                case 10: // +1PPP####### -> C (PPP) ###-####
                    country = 1;
                    city = value.slice(0, 3);
                    number = value.slice(3);
                    break;

                case 11: // +CPPP####### -> CCC (PP) ###-####
                    country = value[0];
                    city = value.slice(1, 4);
                    number = value.slice(4);
                    break;

                case 12: // +CCCPP####### -> CCC (PP) ###-####
                    country = value.slice(0, 3);
                    city = value.slice(3, 5);
                    number = value.slice(5);
                    break;

                default:
                    return tel;
            }

            if (country == 1) {
                country = "";
            }

            number = number.slice(0, 3) + '-' + number.slice(3);

            return (country + " " + city + "-" + number).trim();
        };
    });

    app.filter('noFractionCurrency',
        [ '$filter', '$locale',
            function(filter, locale) {
                var currencyFilter = filter('currency');
                var formats = locale.NUMBER_FORMATS;
                return function(amount, currencySymbol) {
                    var value = currencyFilter(amount, currencySymbol);
                    var sep = value.indexOf(formats.DECIMAL_SEP);
                    if (value.substring(sep + 1) !== '00') {
                        return value;
                    }

                    if(amount >= 0) {
                        return value.substring(0, sep);
                    }
                    return value.substring(0, sep) + ')';
                };
            } ]
    );

    app.directive('onlyNum', function() {
        return function(scope, element, attrs) {

            var keyCode = [8,9,37,39,48,49,50,51,52,53,54,55,56,57,96,97,98,99,100,101,102,103,104,105,110];
            element.bind("keydown", function(event) {
                if($.inArray(event.which,keyCode) == -1) {
                    scope.$apply(function(){
                        scope.$eval(attrs.onlyNum);
                        event.preventDefault();
                    });
                    event.preventDefault();
                }

            });
        };
    });

    app.directive('onlyDecimal', function() {
        return function(scope, element, attrs) {

            var keyCode = [8,9,37,39,48,49,50,51,52,53,54,55,56,57,96,97,98,99,100,101,102,103,104,105,110, 190];
            element.bind("keydown", function(event) {
                if($.inArray(event.which,keyCode) == -1) {
                    scope.$apply(function(){
                        scope.$eval(attrs.onlyNum);
                        event.preventDefault();
                    });
                    event.preventDefault();
                }
            });
        };
    });

    app.directive('smartDecimal', function () {
        return {
            require: 'ngModel',
            link: function (scope, element, attrs, ngModel) {
                scope.$watch(attrs.ngModel, function(newValue, oldValue) {
                    var spiltArray = String(newValue).split("");

                    if (spiltArray.length === 0) return;
                    if (spiltArray.length === 1 && (spiltArray[0] == '-' || spiltArray[0] === '.' )) return;
                    if (spiltArray.length === 2 && newValue === '-.') return;

                    if(attrs.max) {
                        var maxValue = parseFloat(attrs.max);
                        if(maxValue < parseFloat(newValue)){
                            newValue = maxValue;
                            ngModel.$setViewValue(newValue);
                            ngModel.$render();
                        }
                    }

                    if(attrs.min) {
                        var minValue = parseFloat(attrs.min);
                        if(minValue > parseFloat(newValue)){
                            newValue = minValue;
                            ngModel.$setViewValue(newValue);
                            ngModel.$render();
                        }
                    }

                    if(attrs.allowNegative == "false") {
                        if(spiltArray[0] == '-') {
                            newValue = newValue.replace("-", "");
                            ngModel.$setViewValue(newValue);
                            ngModel.$render();
                        }
                    }

                    if(attrs.allowDecimal == "false") {
                        newValue = parseInt(newValue);
                        ngModel.$setViewValue(newValue);
                        ngModel.$render();
                    }

                    if(attrs.allowDecimal != "false") {
                        var fractionNumber = 2;
                        if(attrs.decimalUpto) {
                            fractionNumber = attrs.decimalUpto;
                        }

                        var n = String(newValue).split(".");
                        if(n[1]) {
                            var n2 = n[1].slice(0, fractionNumber);
                            newValue = [n[0], n2].join(".");
                            ngModel.$setViewValue(newValue);
                            ngModel.$render();
                        }
                    }

                    /*Check it is number or not.*/
                    if (isNaN(newValue)) {
                        ngModel.$setViewValue(oldValue);
                        ngModel.$render();
                    }
                });
            }
        };
    });

    app.directive('inputMaxLengthNumber', function() {
        return {
            require: 'ngModel',
            restrict: 'A',
            link: function (scope, element, attrs, ngModelCtrl) {
                function fromUser(text) {
                    var maxlength = Number(attrs.maxlength);
                    if (String(text).length > maxlength) {
                        ngModelCtrl.$setViewValue(ngModelCtrl.$modelValue);
                        ngModelCtrl.$render();
                        return ngModelCtrl.$modelValue;
                    }
                    return text;
                }
                ngModelCtrl.$parsers.push(fromUser);
            }
        };
    });

    app.controller('GlobalCtrl', [
        "$scope", '$compile', function($scope, $compile) {
            $scope.compile_content = function(data) {
                $compile(data)($scope);
                $scope.$apply();
            };
        }
    ]);
    
    this.app = app;

}).call(this);