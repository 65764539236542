(function () {
  app.controller('SearchPageCtrl', [
    "$scope", '$timeout',
    function ($scope, $timeout) {
      $scope.wax_tracker_url = '';
      $scope.initialize_filter_options_url = '';
      $scope.no_image_url = '';

      $scope.key_search = '';
      $scope.suggestions = [];
      $scope.all_results = false;
      $scope.sport = [];
      $scope.brand = [];
      $scope.year = [];
      $scope.box_type = [];

      $scope.sport_options = {};
      $scope.brand_options = {};
      $scope.year_options = {};
      $scope.box_type_options = {};
      $scope.page = 0;
      $scope.can_show_more = true;
      $scope.no_slugs = [];
      $scope.is_loading_suggestion = false;
      $scope.tags = {
        sports: [],
        brands: [],
        years: [],
        box_types: []
      }

      $scope.data_box_histories = [];

      $scope.$watchGroup(['sport', 'brand', 'year', 'box_type'], function(newValues, oldValues, scope) {
        try {
          let sports = [],
              brands = [],
              years = [],
              box_types = [];
          newValues[0].forEach(id => {
            Object.values($scope.sport_options).forEach(o => {
              let temp = o.filter(f => f.id == id);
              sports = [...sports,...temp];
            })
          })
          newValues[1].forEach(id => {
            let temp = Object.values($scope.brand_options)[0].filter(f => f.id == id);
            brands = [...brands,...temp];
          })
          newValues[2].forEach(id => {
            let temp = Object.values($scope.year_options)[0].filter(f => f.id == id);
            years = [...years,...temp];
          })
          newValues[3].forEach(id => {
            Object.values($scope.box_type_options).forEach(o => {
              let temp = o.filter(f => f.id == id);
              box_types = [...box_types,...temp];
            })
          })
          $timeout(function () {
            $scope.tags = {
              sports: sports,
              brands: brands,
              years: years,
              box_types: box_types
            }
          })
        } catch (error) {
          console.log(error);
        }
      });

      $scope.init = function () {};

      $scope.getSuggestions = function (load_more = false) {
        if ($scope.key_search == '') {
          $scope.suggestions = []; 
          return;
        }
        if (!load_more) {
          $scope.page = 0;
        }
        $scope.is_loading_suggestion = true;
        $.ajax({
          beforeSend: function(){
            $(".loading").hide();
            $(".loading").attr('style', 'background: rgba(0, 0, 0, 0)');
            $(".loading").attr('style', 'z-index: -1 !important');
          },
          url: $scope.waxtracker_suggestions_url,
          dataType: "json",
          cache: false,
          type: "GET",
          data: { 
            q: $scope.key_search,
            page: $scope.page,
            sport: $scope.sport,
            brand: $scope.brand,
            year: $scope.year,
            box_type: $scope.box_type
           },
          afterSend: function(){},
          success: function (rs) {
            $timeout(function () {
              if (rs.succeed) {
                $scope.is_loading_more = false;
                $scope.all_results = false;
                $scope.can_show_more = rs.is_more;
                $scope.total_results = rs.total_results;
                if ($scope.page > 0) {
                  $scope.suggestions = [...$scope.suggestions,...rs.data];
                } else {
                  $scope.suggestions = rs.data;
                }
                $scope.getChart(rs.data);
              } else {
                $scope.suggestions = [];
              }
              $scope.is_loading_suggestion = false;
            });
          }
        });
      };

       $scope.initializeFilterOptions = function() {
        $.ajax({
          url: $scope.initialize_filter_options_url,
          dataType: "json",
          cache: false,
          type: "GET",
          data: {},
          success: function (rs) {
            $timeout(function () {
              if (rs.succeed) {
                $scope.sport_options = rs.data['sport'];
                $scope.brand_options = rs.data['brand'];
                $scope.year_options = rs.data['year'];
                $scope.box_type_options = rs.data['box_type'];
              } else {
                toastr.error(rs.message);
              }
            });
          }
        });
      }

      $scope.seeAllResults = function () {
        if( !Object.keys($scope.sport_options).length ||
            !Object.keys($scope.brand_options).length ||
            !Object.keys($scope.year_options).length ||
            !Object.keys($scope.box_type_options).length ) {
          $scope.initializeFilterOptions();
        }

        $scope.sport = getFilter('sport_options');
        $scope.brand = getFilter('brand_options');
        $scope.year = getFilter('year_options');
        $scope.box_type = getFilter('box_type_options');
        $scope.page = 0;

        $scope.getSuggestions();
      };

      function getFilter(name_of_elements) {
        let result = [];
        $(`input[name="${name_of_elements}"]`).each(function() {
          let currentElement = $(this);
          if (currentElement.is(':checked')) {
            
            result.push(parseInt(currentElement.val())); 
          }
        });
        
        return result;
      }

      function emptyCheck(obj) {
        Object.keys(obj).length === 0 && obj.constructor === Object;
      }

      $scope.closeSearch = function (){
        $("#search-input").blur();
        $("#search-page").removeClass('h-100');
        $(".content-wrapper").removeClass('hide-content-wrapper');
        $scope.key_search = '';
        $scope.sport = [];
        $scope.brand = [];
        $scope.year = [];
        $scope.box_type = [];
        $scope.suggestions = [];
      }

      $scope.openSearch = function (){
        $("#search-toggler").blur();
        $("#search-page").addClass('h-100');
        $(".content-wrapper").addClass('hide-content-wrapper');
        setTimeout(() => {
          $("html, body").animate({ scrollTop: "0" });
          $("#search-input").focus();
        }, 100);
        $scope.getSuggestions();
        $scope.getBoxSearchHistories();
      }

      $scope.getChart = function(data) {
        let ids = data.map(row => row.id);
        if (ids && ids.length == 0)
          return;
        $.ajax({
          url: $scope.index_data_chart_url,
          dataType: "json",
          cache: false,
          type: "GET",
          data: {
            ids: ids,
            selected_chart_option: '7_days',
            chart_type: 'price',
          },
          success: function (rs) {
            $timeout(function () {
              if (rs.succeed) {
                Object.keys(rs.result).forEach(key => {
                  if (rs.result[key] == {}) {
                    return;
                  }
                  let data = rs.result[key].chart_data[0].data.sort().map(row => ({ x: row[0], y: row[1] }));
                  new Chart(`chart-search-${key}`,{
                    type: 'line',
                    data: {
                      datasets: [
                        {
                          data: data,
                          borderColor: rs.result[key].color,
                          pointRadius: 0,
                          poitnHoverRadius: 0,
                          hoverRadius: 0
                        }
                      ]
                    },
                    options: {
                      animation: false,
                      plugins: {
                        legend: {
                          display: false,
                        },
                        tooltip: {
                          enabled: false
                        }
                      },
                      scales: {
                        x: {
                          display: false
                        },
                        y: {
                          display: false
                        }
                      }
                    }
                  });
                });
              }
            });
          },
        });
      }

      $scope.loadMore = function() {
        $scope.is_loading_more = true;
        $scope.page += 1;
        $scope.getSuggestions(true);
      }

      $scope.getEvent = function (e) {
        e.stopPropagation();
      }

      $scope.removeTags = function (type, id) {
        $timeout(function () {
          switch (type) {
            case "sports":
                $scope.sport = $scope.sport.filter(f => f != id);
              break;
            case "brands":
              $scope.brand = $scope.brand.filter(f => f != id);
              break;
            case "years":
              $scope.year = $scope.year.filter(f => f != id);
              break;
            case "box_types":
              $scope.box_type = $scope.box_type.filter(f => f != id);
              break;
            default:
              break;
          }
          $scope.$apply();
          $scope.seeAllResults();
        })
      }

      $scope.getBoxSearchHistories = function () {
        if($scope.box_histories.length == 0) return;
        $.ajax({
          beforeSend: function(){
            $(".loading").hide();
            $(".loading").attr('style', 'background: rgba(0, 0, 0, 0)');
            $(".loading").attr('style', 'z-index: -1 !important');
          },
          url: $scope.data_boxes_url,
          dataType: "json",
          cache: false,
          type: "POST",
          data: { slugs: $scope.box_histories },
          success: function (rs) {
            $timeout(function () {
              if (rs.succeed) {
                $scope.data_box_histories = rs.data;
              } else {
                // toastr.error("An Unexpected Error Occurred!");
                $scope.data_box_histories = [];
              }
            });
          },
        })
      }

      $scope.addBoxHistory = function (box_slug) {
        try {
          let temp = $scope.box_histories;
          while (temp.indexOf(box_slug) !== -1) {
            temp.splice(temp.indexOf(box_slug), 1);
          }
          temp.unshift(box_slug);
          $scope.box_histories = temp.slice(0,6);
        } catch (error) {
          $scope.box_histories = [];
        }
        localStorage.setItem('box_histories', $scope.box_histories);
      }
    }
  ]);

}).call(this);
