(function () {
    app.controller('ListingCtrl', [
        "$scope", '$timeout',
        function ($scope, $timeout) {
            $scope.step = 0;
            $scope.start_time = '';
            $scope.name = '';
            $scope.league_name = '';
            $scope.notes = '';
            $scope.league_years = [];
            $scope.listing_type = '0';
            $scope.listing_type_name = '';
            $scope.listing_teams = [];
            $scope.buyer_names = [];

            $scope.msg_name_error = '';
            $scope.msg_youtube_error = '';
            $scope.msg_league_error = '';
            $scope.msg_buyer_names_error = '';

            $scope.remain_chars_name = 60;
            $scope.remain_chars_notes = 1000;

            const LISTING_TYPES = {
                BREAK_BY_TEAM: 0,
                RANDOM: 1,
            };

            $scope.init = function () {
                $scope.getListingTeams();
                if ($scope.all_league_years) {
                    $scope.all_league_years.forEach((ly, idx) => {
                        let checked = $('#year_' + idx).is(':checked');
                        if (checked && !$scope.league_years.includes(ly.league_year)) {
                            $scope.league_years.push(ly.league_year);
                        }
                    });
                }
            };

            $scope.previousStep = function () {
                if ($scope.step == 0) return;
                if ($scope.step == 2 && $scope.listing_type == LISTING_TYPES.BREAK_BY_TEAM) {
                    $scope.step = 0;
                    return;
                }
                $scope.step -= 1;
            }

            $scope.nextStep = function (type) {
                if ($scope.step == 0) {
                    if (!$scope.validateNewListingData()) {
                        return;
                    }
                    $scope.getListingTeams();
                    if ($scope.listing_type == LISTING_TYPES.BREAK_BY_TEAM) {
                        $scope.step = 2;
                        return;
                    }
                } else if ($scope.step == 1 || $scope.step == 2) {
                    if (!$scope.validateBuyerNames()) {
                        return;
                    }
                    if (type && type == 'skip') {
                        if ($scope.is_new_record) {
                            $scope.listing_teams = $scope.listing_teams.map(lt => {
                                return {
                                    id: lt.id,
                                    team_id: lt.team_id,
                                    team_name: lt.team_name,
                                    buyer_name: 'OPEN',
                                }
                            });
                        }
                    }
                }
                $scope.step += 1;
            }

            $scope.validateNewListingData = function () {
                $scope.msg_name_error = '';
                $scope.msg_youtube_error = '';
                $scope.msg_league_error = '';

                if ($.trim($scope.name) == '') {
                    $scope.msg_name_error = 'Listing Name is required!';
                    return false;
                }

                if ($.trim($scope.youtube_url) == "" || !validateYoutubeUrl("https://www.youtube.com/embed/" + $scope.youtube_url)) {
                    $scope.msg_youtube_error = 'Youtube URL is blank or incorrect format!';
                    return false;
                }

                if ($scope.league_id == null || $.trim($scope.league_id) == '') {
                    $scope.msg_league_error = 'League is required!';
                    return false;
                }

                let imagesAmount = document.getElementById("listing_images").files.length;
                if (imagesAmount > $scope.max_images_upload) {
                    return false;
                }

                // TODO: validate start time: in the future
                let dd = $('#listing_start_time_3i').val();
                let mm = $('#listing_start_time_2i option:selected').text();
                let yy = $('#listing_start_time_1i').val();
                let HH = $('#listing_start_time_4i').val();
                let MM = $('#listing_start_time_5i').val();
                $scope.start_time = `${dd} ${mm}, ${yy} ${HH}:${MM}`;
                return true;
            }

            $scope.validateBuyerNames = function () {
                $scope.msg_buyer_names_error = '';
                $scope.buyer_names = [];

                // break line
                $.each($('#buyer_names').val().split(/\n/), function (i, line) {
                    if (line) {
                        $scope.buyer_names.push(line);
                    }
                });

                // validate
                if ($scope.buyer_names.length > $scope.listing_teams.length) {
                    $scope.msg_buyer_names_error = `You have too many names. Please reduce your names no more than ${$scope.listing_teams.length}!`;
                    return false;
                }

                $scope.listing_teams = $scope.listing_teams.map((lt, i) => {
                    let buyer_name = 'OPEN';
                    if (lt.buyer_name) {
                        buyer_name = lt.buyer_name;
                    } else {
                        if ($scope.buyer_names[i] && $scope.buyer_names[i] != '') {
                            buyer_name = $scope.buyer_names[i];
                        }
                    }
                    return {
                        id: lt.id,
                        team_id: lt.team_id,
                        team_name: lt.team_name,
                        buyer_name: buyer_name,
                    }
                });
                return true;
            }

            $scope.getListingTeams = function () {
                $scope.listing_teams = [];
                const listing_id = $scope.is_new_record ? 0 : parseInt($scope.listing_id);
                $.post('/listings/' + listing_id + '/teams/' + parseInt($scope.league_id), {}, function (rs) {
                    if (rs.success) {
                        $scope.listing_teams = rs.listing_teams.length > 0 ? rs.listing_teams : [];
                    } else {
                        $scope.listing_teams = [];
                    }
                    $scope.$apply();
                });
            }

            $scope.updateLeagueYears = function (league_year, checkbox_id) {
                let checked = $('#' + checkbox_id).is(':checked');
                if (checked && !$scope.league_years.includes(league_year)) {
                    $scope.league_years.push(league_year);
                }
                if (!checked) {
                    $scope.league_years = $.grep($scope.league_years, function (value) {
                        return value != league_year;
                    });
                }
            }

            $scope.$watch('league_id', function () {
                $scope.league_name = $("#listing_league_id :selected").text();
            });

            $scope.$watch('listing_type', function () {
                $scope.listing_type_name = $("#listing_listing_type :selected").text();
            });

            $scope.$watch('name', function () {
                $scope.remain_chars_name = 60 - $scope.name.length;
            });

            $scope.$watch('notes', function () {
                $scope.remain_chars_notes = 1000 - $scope.notes.length;
            });

            function validateYoutubeUrl(url) {
                let regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
                return url.match(regExp);
                //--> get youtube URL
                // return (match&&match[7].length==11)? match[7] : false;
            };

            function shuffle(array) {
                let currentIndex = array.length, temporaryValue, randomIndex;
                // While there remain elements to shuffle...
                while (0 !== currentIndex) {
                    // Pick a remaining element...
                    randomIndex = Math.floor(Math.random() * currentIndex);
                    currentIndex -= 1;

                    // And swap it with the current element.
                    temporaryValue = array[currentIndex];
                    array[currentIndex] = array[randomIndex];
                    array[randomIndex] = temporaryValue;
                }
                return array;
            };

            $scope.randomize = function (type) {
                let lt_teams = $scope.listing_teams;
                if (type == 'team') {
                    const sf = shuffle(lt_teams.map(x => {
                        return {
                            team_id: x.team_id,
                            team_name: x.team_name
                        }
                    }));
                    const buyerArr = lt_teams.map(x => x.buyer_name);
                    $scope.listing_teams = $scope.listing_teams.map((lt, idx) => {
                        return {
                            buyer_name: buyerArr[idx],
                            id: lt.id,
                            team_id: sf[idx].team_id,
                            team_name: sf[idx].team_name
                        };
                    });
                    $scope.teams_random_times += 1;
                } else if (type == 'buyer') {
                    const sf = shuffle(lt_teams.map(x => x.buyer_name));
                    const teamArr = lt_teams.map(x => x.team_name);
                    $scope.listing_teams = $scope.listing_teams.map((lt, idx) => {
                        return {
                            team_name: teamArr[idx],
                            id: lt.id,
                            team_id: lt.team_id,
                            buyer_name: sf[idx]
                        };
                    });
                    $scope.buyers_random_times += 1;
                }
            };
        }
    ]);

}).call(this);
