(function () {
  app.controller('PYTCtrl', [
    "$scope", '$timeout',
    function ($scope, $timeout) {

      $scope.init = function () {}

      $scope.changePrice = function (index) {
        let price = Number($scope.pick_your_team_descriptions[index].price);
        if ($scope.pick_your_team_descriptions[index].price == '') return;
        if (isNaN(price) || price < 0) price = 0;
        price = price.toFixed(2);
        $scope.pick_your_team_descriptions[index].price = price;
      }

      $scope.changePercentage = function () {
        let percentage = Number($scope.percentage);
        if ($scope.percentage == '') return;
        if (isNaN(percentage) || percentage < 0 || percentage > 100) percentage = 0;
        percentage = percentage.toFixed(2);
        $scope.percentage = percentage;
      }
      
      $scope.submit = function () {
        if (!$scope.source_id) return;
        let params = {
          source_id: $scope.source_id,
          pick_your_team_descriptions: $scope.pick_your_team_descriptions,
          pyt_id: $scope.pick_your_team_id,
          percentage: $scope.percentage,
          notes: $scope.notes
        }
        $.ajax({
          // beforeSend: function(){
          //   $(".loading").hide();
          //   $(".loading").attr('style', 'background: rgba(0, 0, 0, 0)');
          //   $(".loading").attr('style', 'z-index: -1 !important');
          // },
          url: $scope.submit_url,
          dataType: "json",
          cache: false,
          type: "POST",
          data: params,
          success: function (rs) {
            $timeout(function () {
              if (rs.succeed) {
                toastr.success('Save PYT Break successful');
                location.href = $scope.pick_your_team_id ? $scope.pick_your_teams_url : $scope.box_url;
              } else {
                toastr.error(rs.message);
              }
            });
          },
        });
      }
    }
  ]);

}).call(this);